/**
 * Steps to configure the app navigation
 * 1- create a .ts file named by the project name under e.g '/src/navigation/' check './demo.ts'
 * 2- import it using the wildcard syntax
 * 3- export it as a key in 'appCustomNavigation' object down; !important: the key must be the 'project id'
 */

import * as ambulansBlekingeLinks from './ambulansBlekinge';
import * as ambulansOrebroLinks from './ambulansOrebro';
import * as ambulansVastmanland from './ambulansVastmanland';
import * as ambulansSahlgrenska from './ambulansSahlgrenska';
import * as ambulansSkane from './ambulansSkane';
import * as demoLinks from './demo';
import * as slasLinks from './slas';
import * as testLinks from './test';
import * as lakemedelGavleborgLinks from './lakemedelGavleborg';
import * as polisAppenLinks from './polisAppen';
import * as stramaGavleborgborgLinks from './stramaGavleborg';
import * as stramaJonkopingLinks from './stramaJonkoping';
import * as stramaNationellLinks from './stramaNationell';
import * as ambulansVasternorrland from './ambulansVasternorrland';
import * as ambulansNorrbotten from './ambulansNorrbotten';
import * as stramaOrebroLinks from './stramaOrebro';
import * as ambulansSormlandLinks from './ambulansSormland';
import * as stramaVarmland from './stramaVarmland';
import * as missingPeople from './missingPeople';
import * as raddningstjanstenSyd from './raddningstjanstenSyd';
import * as falckAmbStockholm from './falckAmbStockholm';
import * as vcBagis from './vcBagis';
import * as ambulansKronoberg from './ambulansKronoberg';
import * as ambulansOstergotland from './ambulansOstergotland';
import * as faluKommun from './faluKommun';
import * as satersKommun from './satersKommun';
import * as ambulansVasterbotten from './ambulansVasterbotten';
import * as ssihSormland from './ssihSormland';
import * as primarvardSormland from './primarvardSormland';
import * as internmedicinHelsingborg from './internmedicinHelsingborg';
import * as raddsamy from './raddsamy';
import * as ambulansHalland from './ambulansHalland';
import * as ambulansJonkoping from './ambulansJonkoping';
import * as akutklinikenNykoping from './akutklinikenNykoping';
import * as samaritenGotland from './samaritenGotland';
import * as showroom from './showroom';
import * as smartyta from './smartyta';
import * as ekgAppen from './ekgAppen';
import * as urologiHelsingborg from './urologiHelsingborg';

/* eslint-disable camelcase */
export const appCustomNavigation = {
  demo: demoLinks,
  test: testLinks,
  slas: slasLinks,
  ambulans_blekinge: ambulansBlekingeLinks,
  ambulans_jonkoping: ambulansJonkoping,
  ambulans_orebro: ambulansOrebroLinks,
  ambulans_vastmanland: ambulansVastmanland,
  ambulans_sahlgrenska: ambulansSahlgrenska,
  ambulans_skane: ambulansSkane,
  lakemedel_gavleborg: lakemedelGavleborgLinks,
  polis_appen: polisAppenLinks,
  strama_gavleborg: stramaGavleborgborgLinks,
  strama_jonkoping: stramaJonkopingLinks,
  strama_nationell: stramaNationellLinks,
  ambulans_vasternorrland: ambulansVasternorrland,
  ambulans_norrbotten: ambulansNorrbotten,
  strama_orebro: stramaOrebroLinks,
  ambulans_sormland: ambulansSormlandLinks,
  strama_varmland: stramaVarmland,
  mps_appen: missingPeople,
  raddningstjansten_syd: raddningstjanstenSyd,
  falck_amb_stockholm: falckAmbStockholm,
  vc_bagis: vcBagis,
  ambulans_ssih_sormland: ssihSormland,
  ambulans_kronoberg: ambulansKronoberg,
  ambulans_ostergotland: ambulansOstergotland,
  falu_kommun: faluKommun,
  saters_kommun: satersKommun,
  ambulans_vasterbotten: ambulansVasterbotten,
  primarvard_sormland: primarvardSormland,
  internmedicin_helsingborg: internmedicinHelsingborg,
  raddsamy,
  ambulans_halland: ambulansHalland,
  akutkliniken_nykoping: akutklinikenNykoping,
  samariten_gotland: samaritenGotland,
  showroom,
  smartyta,
  ekg_appen: ekgAppen,
  urologi_helsingborg: urologiHelsingborg,
};
