/**
 * project navigation
 * @param { string } to - link path
 * @param { string } title - link title
 * ---
 * @param to must be on of the tow cases
 * 1- for internal categories: is must be the category id
 * 2- for external links: 'folder' name which will be included '/public/{project_id}/folder'
 * ---
 * in error case in @param to (when no actual associated category found)
 * the link will be rendered as external link
 *
 */

export default [
  { to: 'allmant', title: 'Allmänt' },
  { to: 'behandlingsriktlinjer', title: 'Behandlingsriktlinjer' },
  { to: 'dosberakning_barn', title: 'Dosberäkning Barn' },
  { to: 'dosberakning_vuxen', title: 'Dosberäkning Vuxen' },
  { to: 'Direktiv', title: 'Direktiv och rekommendationer' },
  { to: 'trauma', title: 'Trauma' },
  { to: 'manual', title: 'Manual' },
  { to: 'changelog', title: 'Ändringslogg' },
  { to: 'd1', title: 'Distrikt 1' },
  { to: 'd2', title: 'Distrikt 2' },
  { to: 'd3', title: 'Distrikt 3' },
  { to: 'd4', title: 'Distrikt 4' },
  { to: 'rslc', title: 'RSLC' },
];
