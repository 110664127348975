import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { style as coreStyle, ApplicationStyle } from '@eir/core';
import { useConcreteProject } from './ProjectContext';

const initStyle = coreStyle.demo;
const StyleContext = createContext<ApplicationStyle>(initStyle);

export const StyleProvider = ({ children }: { children?: ReactNode }): ReactElement => {
  const [appColors, setAppColors] = useState<ApplicationStyle>(initStyle);
  const project = useConcreteProject();
  useEffect(() => {
    const appStyle = coreStyle[project.id];
    console.log(project.id);
    console.log(appStyle);

    setAppColors(appStyle);
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
    :root {
      --color-primary: ${appStyle.primary} !important;
      --color-accent: ${appStyle.accent} !important;
      --color-primary-dark: ${appStyle.primaryDark} !important;
      --color-primary-light: ${appStyle.primaryLight} !important;
    }`;
    document.head.appendChild(styleTag);
  }, [project.id]);
  return <StyleContext.Provider value={appColors}>{children}</StyleContext.Provider>;
};

export const useStyle = (): ApplicationStyle => useContext(StyleContext);
